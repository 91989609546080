import React, { useEffect, useState } from "react"
import $ from "jquery"
import PropTypes from "prop-types"
import { withPrefix, useStaticQuery, graphql } from "gatsby"
import "swiper/swiper-bundle.css"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/ContentBuilder/Header"
import "../components/layout.scss"
import "../scss/style.scss"
import FooterNavMobile from "../components/ContentBuilder/FooterNavMobile"

const Page = ({ children, hideHeader, pageData }) => {
  useEffect(() => {
    $(".select-dropdown").selectpicker({
      style: "",
      dropupAuto: false,
      width: "auto",
    })
    if (typeof pageData !== "undefined") {
      _setPageData(pageData)
    }
  }, [])
  
  const globalConfigRaw = useStaticQuery(graphql`
    {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          turnOnExplore
        }
      }
    }
  `)
  const globalConfig = globalConfigRaw.silverStripeDataObject.UndigitalGlobalConfig
  
  const [scroll, setScroll] = useState(false)
  const [_pageData, _setPageData] = useState({
    pageClass: "",
    bodyClass: "",
    pageTitle: "",
  })
  if (_pageData.bodyClass === "homepage" && window.innerWidth > 991) {
    window.addEventListener("scroll", () => {
      let mainOffset = document.getElementById("main").getBoundingClientRect()
      setScroll(mainOffset.top < 350)
    })
  }
  return (
    <>
      <div
        className={`page-wrap ${_pageData.bodyClass} ${
          scroll ? " explore-scroll" : ""
        }`}
      >
        <Header pageData={pageData} siteName={_pageData.bodyClass} />
        <main id="main">{children}</main>

        <Footer />
        <FooterNavMobile />
      </div>

      <Helmet>
        <script src={withPrefix("../../scripts/script.js")} type="text/babel" />
        <script src={withPrefix("../../scripts/filter.js")} type="text/babel" />
        {globalConfig.turnOnExplore ==  0 && (
        <meta name="robots" content="noindex" />
        )}
      </Helmet>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page